












































































import { Vue, Component } from "vue-property-decorator";
import { VCR, VCRFilter, VCRStatus } from "@/dto/payroll/VacationDTO";
import VacationRequestDetails from "@/components/payroll/vacation/VacationRequestDetails.vue";
import VacationRequestCreationModal from "@/components/payroll/vacation/VacationRequestCreationModal.vue";
import { WorkspaceType } from "@/dto/auth/Workspace";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import { processError } from "@/utils/ComponentUtils";
import EmploymentService from "@/services/EmploymentService";
import { IEmployment } from "@/dto/payroll/IEmployment";
import { EmploymentFilter } from "@/dto/payroll/Filters";
import { CounterpartyType } from "@/constants/CounterpartyType";
import { EmploymentStatus } from "@/components/payroll/business/employments/contractors/Contractor";
import { EventBus } from "@/utils/EventBus";

@Component({
  components: { VacationRequestDetails, PaginationComponent },
  computed: {
    VCRStatus() {
      return VCRStatus;
    },
    WorkspaceType() {
      return WorkspaceType;
    }
  }
})
export default class VacationRequests extends Vue {

  private vacationRequests: Array<VCR> = [];
  private vacationRequestsFilter = new VCRFilter();
  private employments: Array<IEmployment> = [];
  private employmentsFilter = new EmploymentFilter(
    {
      types: ["STAFF", "FOREIGN"],
      contractorType: CounterpartyType.PERSON,
      status: [EmploymentStatus.ACTIVE, EmploymentStatus.NEW, EmploymentStatus.PENDING_FOR_DOCUMENTS]
    }
  );
  private totalPages = 1;

  mounted(): void {
    EventBus.$on('changeStatusVacationRequest', () => this.loadVacationRequests());
    this.loadVacationRequests();
  }

  beforeDestroy() {
    EventBus.$off('changeStatusVacationRequest');
  }

  private async loadVacationRequests() {
    Application.startLoading();
    await this.loadEmployments();
    if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
      this.vacationRequestsFilter.employmentsId = this.employments.map(item => item.id!);
    }
    if (this.$wss.getCurrent.type === WorkspaceType.COMPANY) {
      this.vacationRequestsFilter.employerId = this.id;
    }
    VacationService.getPageOfVacationRequests(this.vacationRequestsFilter).then(
      res => {
        this.vacationRequests = res.data.data;
        this.totalPages = res.data.countOfPages;
        Application.stopLoading();
      },
      err => processError(err, this)
    )
  }

  private async loadEmployments() {
    try {
      Application.startLoading();
      if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
        this.employmentsFilter.detailsId = this.id;
      } else {
        this.employmentsFilter.employerId = this.id;
      }
      const res = await EmploymentService.getAllByFilter<IEmployment>(this.employmentsFilter);
      this.employments = res.data;
    } catch (err) {
      processError(err, this);
    } finally {
      Application.stopLoading();
    }
  }

  private changeStatusVacationRequest() {
    this.loadVacationRequests();
  }

  private showVacationRequestCreationModal() {
    this.$modal.show(VacationRequestCreationModal,
      {
        employments: this.employments,
        callback: this.loadVacationRequests
      });
  }

  private changePage(page: number) {
    this.vacationRequestsFilter.page = page - 1;
    this.loadVacationRequests();
  }

  get id(): number {
    return this.$wss.getCurrent.id;
  }

}
